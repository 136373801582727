import { DropdownMenuContent, DropdownMenuLabel } from '@radix-ui/react-dropdown-menu';
import Link from 'next/link';
import React from 'react';
import { usePathname } from 'next/navigation';

export const Navigation = ({ items }: { items: { label: string; href: string }[] }) => {
  const pathName = usePathname();

  return (
    <DropdownMenuContent>
      <div className="overflow-hidden border mt-1 mr-4 rounded-lg bg-menu-background border-navigation-border">
        {items.map((item) => (
          <div
            key={item.label}
            className={`cursor-pointer hover:bg-menu-active ${pathName === item.href ? 'bg-menu-active' : ''}`}>
            <DropdownMenuLabel className="px-3 py-2">
              <Link
                className="w-full block [&:not(:last-child)]:border-b border-menu-border hover:bg-menu-hover"
                href={item.href}>
                {item.label}
              </Link>
            </DropdownMenuLabel>
          </div>
        ))}
      </div>
    </DropdownMenuContent>
  );
};
