'use client';

import { Logo } from 'components/Logo';
import { ArrowLeftIcon, HamburgerMenuIcon } from '@radix-ui/react-icons';
import { Button, ButtonProperties } from 'components/ui/button';
import { useEffect, useMemo, useState } from 'react';
import { DropdownMenu, DropdownMenuTrigger } from '@radix-ui/react-dropdown-menu';
import { usePathname } from 'next/navigation';
import { ProgressBar, ProgressBarProperties } from './ProgressBar';
import { Navigation } from 'components/Navigation';
import Link from 'next/link';

const NAVIGATION_ITEMS = [
  { label: 'Home', href: 'https://gift-i.nl' },
  { label: 'Hoe werkt het?', href: 'https://www.gift-i.nl/#trigger-hoe' },
  { label: "Mijn gifti's", href: '/dashboard' },
];

interface ProgressHeaderProperties {
  onBack?: () => void;
  progress: Pick<ProgressBarProperties, 'currentStep' | 'totalSteps'>;
}

interface SiteHeaderProperties {
  showMenu?: boolean;
  centerLogo?: boolean;
}

export const SiteHeader = ({ showMenu = false, centerLogo = false }: SiteHeaderProperties) => (
  <header className={`flex flex-row items-center w-full gap-4 py-4 ${centerLogo ? 'justify-center' : ''}`}>
    <Link href="https://gift-i.nl" className="[&_svg]:w-24 mr-2">
      <Logo />
    </Link>
    {!!showMenu && (
      <div className="ml-auto">
        <Menu />
      </div>
    )}
  </header>
);

export const ProgressHeader = ({ onBack, progress }: ProgressHeaderProperties) => (
  <header className="flex flex-row items-center w-full gap-4 py-4">
    {!!onBack && <BackButton onClick={onBack} />}
    <Link href="https://gift-i.nl" className="[&_svg]:w-24 mr-2">
      <Logo />
    </Link>
    <ProgressBar {...progress} />
    <div className="ml-auto">
      <Menu />
    </div>
  </header>
);

const BackButton = (properties: ButtonProperties) => (
  <div>
    <Button variant="secondary" className="flex items-center justify-center w-8 h-8 p-0 rounded-full" {...properties}>
      <ArrowLeftIcon className="w-5 h-5" />
    </Button>
  </div>
);

const Menu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const pathName = usePathname();

  useEffect(() => {
    setIsOpen(false);
  }, [pathName]);

  const menuLabel = useMemo(() => NAVIGATION_ITEMS.find((item) => item.href === pathName)?.label || 'Menu', [pathName]);

  return (
    <DropdownMenu open={isOpen} onOpenChange={() => setIsOpen(false)}>
      <DropdownMenuTrigger
        onClick={() => setIsOpen(true)}
        className="flex items-center gap-2 px-4 py-2 text-sm font-bold transition-colors border border-menu-border bg-menu-background rounded-3xl">
        {pathName === 'https://gift-i.nl' && <HamburgerMenuIcon className="w-5 h-5" />}
        {menuLabel}
      </DropdownMenuTrigger>
      {!!isOpen && <Navigation items={NAVIGATION_ITEMS} />}
    </DropdownMenu>
  );
};
