export const Logo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 89 46">
    <g filter="url(#filter0_dd_311_1862)">
      <path
        fill="white"
        fillRule="evenodd"
        d="M69 6c.3-.6.7-1.1 1.3-1.6a9 9 0 0 1 12.1 0 8 8 0 0 1 2.7 6.3c0 1.6-.4 3-1.2 4.4.2.6.4 1.3.4 2v18.5c0 3.1-2.6 5.7-5.7 5.7H74c-.4 0-.8 0-1.2-.2l-1.5.4-2.9.2a13 13 0 0 1-9.3-3.3l-1-1.3a5.7 5.7 0 0 1-5.5 4.2h-4.5c-1 0-2-.3-2.7-.7a6 6 0 0 1-2.7.7h-4.5c-2 0-3.8-1-4.8-2.7l-1.3.8a20 20 0 0 1-9.6 2.2 20.5 20.5 0 0 1-10-2.4c-3-1.7-5.4-4-7-7h-.1a20 20 0 0 1-2.5-10 18.5 18.5 0 0 1 9.6-16.8c3-1.6 6.4-2.4 10-2.4a20.3 20.3 0 0 1 10 2.5 8 8 0 0 1 8-3l1.7-.2c2 0 4.1 1 5.6 2.4C50 3.4 52.7 3 55.3 3a16.2 16.2 0 0 1 3.3.3c1.8.4 3 1.6 3.7 3l1.8-.7c1.7-.6 3.4-.4 4.8.4Zm-15.4 7.9a2.1 2.1 0 0 1 .5-.7m-9.4-1.7c.2-.4.3-1 .3-1.5 0-.7-.3-1.4-.7-1.8a3 3 0 0 0-2-1 2.3 2.3 0 0 0-1.7.7l-.1-.1c-.5-.4-1-.6-1.6-.6a3 3 0 0 0-2.4 1.3 2.7 2.7 0 0 0-.4 2c0 .4.2.9.4 1.3a5 5 0 0 0 1.8 2A11.3 11.3 0 0 0 40 15l.3.2h.3l.4-.2a12.4 12.4 0 0 0 1.7-1.1 6.6 6.6 0 0 0 2-2.4Zm22.5 25.2c-2-.2-3.4-.8-4.4-1.8l4.4 1.8ZM79 13.3a3.4 3.4 0 0 1-2.6 1 3.8 3.8 0 0 1-3.7-2.6l-.1-1c0-1.1.3-2 1-2.7.8-.7 1.8-1 2.8-1 1 0 1.9.3 2.6 1 .7.6 1 1.6 1 2.7v1c-.2.6-.5 1.2-1 1.6ZM68.5 36.8l-1.3-.1h1.3Zm0 0a9.1 9.1 0 0 0 1.4-.1 9.4 9.4 0 0 0 1.8-.4l.3-.2v-.3"
        clipRule="evenodd"
      />
      <path
        fill="#EC6B0A"
        d="M73.4 35.6c0 .4.3.7.7.7h4.5c.4 0 .7-.3.7-.7V17.2c0-.4-.3-.7-.7-.7H74c-.4 0-.7.3-.7.7v18.4Z"
      />
      <path
        fill="#EC6B0A"
        d="M73.7 13.3c.7.8 1.7 1.1 2.7 1.1a3.6 3.6 0 0 0 3.7-3.7c0-1.1-.4-2-1.1-2.7-.7-.7-1.6-1-2.6-1s-2 .3-2.7 1c-.8.6-1.1 1.6-1.1 2.7 0 1 .3 2 1 2.6Z"
      />
      <path
        fill="#EC6B0A"
        d="M62.8 35c1.2 1.2 3.2 1.8 5.7 1.8a12.2 12.2 0 0 0 3.2-.5c.2 0 .4-.3.4-.5v-3.1c0-.5-.5-.8-1-.7a5 5 0 0 1-1.2.1c-1 0-1.8-.2-2.3-.6-.5-.5-.8-1.1-.8-2v-8.6h4.5c.4 0 .8-.3.8-.7v-3c0-.4-.4-.7-.8-.7h-4.5v-5.6c0-.5-.5-.9-1-.7l-4.4 1.7c-.3 0-.5.3-.5.6v4h-7.5v-1.3c0-.9.2-1.5.7-2 .5-.5 1.2-.7 2.2-.7h1c.3.1.6-.1.6-.5V8.8c0-.4-.2-.6-.5-.7l-.7-.1-1.4-.1c-2.6 0-4.6.6-5.9 1.8-1.3 1.2-2 3-2 5.4v1.4h-2.6c-.4 0-.7.3-.7.7v3c0 .4.3.7.7.7h2.6v14.7c0 .4.4.7.8.7h4.5c.4 0 .7-.3.7-.7V21h7.5v8.4c0 2.5.6 4.4 1.9 5.6Z"
      />
      <path
        fill="#EC6B0A"
        d="M37.6 35.6c0 .4.3.7.7.7h4.5c.4 0 .7-.3.7-.7V17.2c0-.4-.3-.7-.7-.7h-4.5c-.4 0-.7.3-.7.7v18.4Z"
      />
      <path
        fill="#EC6B0A"
        d="M22.6 36.7A13.8 13.8 0 0 1 8 22.2c0-2.7.6-5.2 1.8-7.4a15 15 0 0 1 12.9-7c2.2 0 4.3.5 6.2 1.3 1.9.9 3.4 2 4.6 3.5 1 1.2 1.8 2.6 2.2 4.2 0 .4-.3.8-.7.8h-4.5a.8.8 0 0 1-.8-.6c-.2-.8-.6-1.5-1.2-2a6.5 6.5 0 0 0-2.5-1.7 8.6 8.6 0 0 0-7.9.5 8 8 0 0 0-3 3.3 13.6 13.6 0 0 0 0 10.3 8 8 0 0 0 3 3.4 9.4 9.4 0 0 0 8.9.2 7.7 7.7 0 0 0 2.7-3 9 9 0 0 0 .8-2.3h-6.7a.7.7 0 0 1-.7-.7v-3.2c0-.4.3-.7.7-.7h11.8c.4 0 .7.3.7.7v1.5c0 2.7-.6 5-1.7 7.1a12 12 0 0 1-4.8 4.7 15 15 0 0 1-7.2 1.6Z"
      />
      <path
        fill="#EC6B0A"
        d="M38.9 7.2a2.8 2.8 0 0 0-2.8 2.8c0 2.3 2 4 4 5l.3.2h.3l.4-.2c1.9-1 4-2.7 4-5 0-1.6-1.5-2.7-2.9-2.8a2 2 0 0 0-1.6.7c-.5-.5-1.1-.7-1.7-.7Z"
      />
    </g>
    <defs>
      <filter
        id="filter0_dd_311_1862"
        width="88.0762"
        height="45.6813"
        x="0"
        y="0"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
        <feBlend in2="BackgroundImageFix" mode="normal" result="effect1_dropShadow_311_1862" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1.5" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
        <feBlend in2="effect1_dropShadow_311_1862" mode="normal" result="effect2_dropShadow_311_1862" />
        <feBlend in="SourceGraphic" in2="effect2_dropShadow_311_1862" mode="normal" result="shape" />
      </filter>
    </defs>
  </svg>
);
