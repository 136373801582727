/* eslint-disable no-nested-ternary */
import { cn } from 'helpers/utils';
import { cva, type VariantProps } from 'class-variance-authority';
import { ReloadIcon } from '@radix-ui/react-icons';
import { Slot } from '@radix-ui/react-slot';
import { ButtonHTMLAttributes, forwardRef } from 'react';
import Link from 'next/link';

const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap rounded-lg text-sm font-bold transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        default:
          'bg-primary b border-primary-border text-primary-foreground shadow outline outline-1 -outline-offset-2 outline-white/20 hover:bg-primary/90',
        secondary: 'border border-white bg-secondary shadow-sm hover:bg-secondary/50',
        tertiary: 'border border-white bg-tertiary shadow-sm hover:bg-tertiary/50',
      },
      size: {
        default: 'px-4 py-2.5',
        sm: 'px-3 text-xs',
        lg: 'px-8',
        icon: 'h-9 w-9',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  }
);

export interface ButtonProperties extends ButtonHTMLAttributes<HTMLButtonElement>, VariantProps<typeof buttonVariants> {
  href?: string;
  asChild?: boolean;
  isLoading?: boolean;
}

const Button = forwardRef<HTMLButtonElement, ButtonProperties>(
  ({ className, children, href, variant, size, asChild = false, disabled, isLoading, ...properties }, reference) => {
    const Comp = href ? Link : asChild ? Slot : 'button';
    const propertiesWithMaybeHref = href ? { ...properties, href } : properties;

    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        //TODO: fix this type
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        ref={reference}
        disabled={disabled || isLoading}
        {...propertiesWithMaybeHref}>
        {isLoading ? <ReloadIcon className="w-4 h-4 mr-2 animate-spin" /> : undefined}
        {children}
      </Comp>
    );
  }
);

Button.displayName = 'Button';

export { Button, buttonVariants };
