'use client';
import { DetailedHTMLProps, HTMLAttributes, useMemo } from 'react';

export interface ProgressBarProperties extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  currentStep?: number;
  totalSteps?: number;
}

export const ProgressBar = ({ currentStep = 1, totalSteps = 4 }: ProgressBarProperties) => {
  const progressBarStyle = useMemo(() => {
    const percentage = (currentStep / totalSteps) * 100;

    return { width: '100%', maxWidth: `${percentage}%`, transition: 'max-width 0.2s ease' };
  }, [currentStep, totalSteps]);

  return (
    <div className="flex flex-col w-full gap-1 py-1 text-xs font-medium">
      <div>
        Stap {currentStep > totalSteps ? totalSteps : currentStep} van {totalSteps}
      </div>
      <div
        aria-valuemax={totalSteps}
        aria-valuemin={0}
        aria-valuenow={currentStep}
        className="box-content flex items-start justify-start w-full h-2 border rounded-lg shadow-inner border-progress-border bg-progress-background"
        role="progressbar">
        <div style={progressBarStyle} className="h-full rounded-lg bg-progress-bar" />
      </div>
    </div>
  );
};
